<template>
	<div>
		<a-button @click="showQrcodeAct">点击查看体验</a-button>
		<a-modal title="查看体验小程序" :visible="showQrcode" :footer="null" width="400px" @cancel="()=>{
			showQrcode = false
		}">
			
			<a-spin :spinning="loading">
				<div class="text-center">
					<div>
						<img :src="imgUrl" style="width: 160px; height: 160px; background: #F2F2F2;" />
					</div>
					<div class="mt16 ft14">扫描二维码查看小程序完整度</div>
				</div>
						
			</a-spin>
			
		</a-modal>
		
	</div>
</template>

<script>
	export default{
		data(){
			return  {
				showQrcode:false,
				loading:false,
				imgUrl:'',
			}
		},
		methods:{
			showQrcodeAct(){
				this.showQrcode = true;
				this.loading = true;
				this.$http.papi('platform/miniapp/getTestQrcode').then(res=>{
					this.loading = false;
					this.imgUrl = res.img;
				}).catch(res=>{
					this.loading = false;
				});
			}
		}
	}
</script>

<style>
	
</style>