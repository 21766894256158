<template>
	<div>
		<a-button @click="uploadAct" type="primary">{{title}}</a-button>
		<a-modal
		      :title="title"
		      :visible="showUploadForm"
		      :confirm-loading="confirmLoading"
		      @ok="handleOk"
		      @cancel="handleCancel"
		    >
		    <a-spin :spinning="loading">
			<div>
				<a-form-model
				    ref="ruleForm"
				    :model="form"
				    :label-col="labelCol"
				    :wrapper-col="wrapperCol"
				  >
					<a-form-model-item label="小程序标题">
					  <a-input :maxLength="32" v-model="form.title" />
					</a-form-model-item>
					<a-form-model-item label="提交分类" placeholder="请选择分类">
					      <a-select v-model="form.cat">
					        <a-select-option v-for="(item,index) in cats" :key="index" :value="index">
					          {{item.first_name}}>{{item.second_name}}
					        </a-select-option>
					      </a-select>
					</a-form-model-item>
					
					<a-form-model-item label="小程序标签" help="小程序的标签，多个标签用“空格”分隔，标签不能多余10个，标签长度不超过20字节">
				      <a-input v-model="form.tag" type="textarea" />
				    </a-form-model-item>
					
				 </a-form-model>
			</div>
			  </a-spin>
		</a-modal>	
			
	</div>
</template>

<script>
	export default{
		props:{
			title:{
				type:String,
				default:'提交审核',
			},
			isUpgrade:{
				type:Boolean,
				default:false,
			}
		},
		data(){
			return {
				showUploadForm:false,
				confirmLoading:false,
				loading:false,
				labelCol: { span: 4 },
				wrapperCol: { span: 14 },
				cats:[],	  
				form:{
					cat:0,
					title:'',
					tag:'',
				},
			}
		},
		methods:{
			uploadAct(){
				this.showUploadForm = true;
				if(this.cats.length == 0){
					this.loading = true;
					this.$http.papi('platform/miniapp/getCategories').then(res=>{
						this.loading = false;
						this.cats = res.cats;
						console.log(this.cats);
						if(this.cats.length == 0){
							this.$message.error('未设置小程序的分类');
						}
					}).catch(res=>{
						this.loading = false;
					});
				}
			},
			handleOk(){
				if(this.confirmLoading == true) return false;
				this.confirmLoading = true;
				let cat = this.cats[this.form.cat];
				this.$http.papi('platform/miniapp/submit',{
					title:this.form.title,
					tag:this.form.tag,
					first_id:cat.first,
					second_id:cat.second,
					first_class:cat.first_name,
					second_class:cat.second_name
				}).then(res=>{
					this.confirmLoading = false;
					this.$message.success('上传成功，等待审核');
					this.refreshPage('/miniapp');
				}).catch(res=>{
					this.confirmLoading = false;
					this.$message.error('上传失败');
				})
			},
			handleCancel(){
				this.showUploadForm = false;
			}
			
		}
	}
</script>

<style>
	
</style>