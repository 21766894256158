<template>
	<div>
		<a-button type="danger" @click="uploadAct" :loading="loading">{{title}}</a-button>
	</div>
</template>

<script>
	export default{
		props:{
			title:{
				type:String,
				default:'上传代码',
			}
		},
		data(){
			return {
				loading:false
			}
		},
		methods:{
			uploadAct(){
				this.loading = true;
				this.$http.papi('platform/miniapp/uploadCode').then(res=>{
					this.loading = false;
					this.$message.success('上传代码成功');
					this.refreshPage('/miniapp');
				}).catch(res=>{
					this.loading = false;
				});
			}
		}
	}
</script>

<style>
	
</style>