<template>
	<div>
		<div class="mb20">
			<span class="ft20 cl-main ftw600">{{value.is_upgrade == 1 ? '升级覆盖线上成功' : '上线运营中'}}</span>
		</div>
	
		<div class="miniapp-form-box mt30">
			<div class="text-center">
					<img class="miniapp-logo" :src="value.wx_face" />
			</div>
			<div class="text-center mt16 ft18 ftw600 cl-main">
				{{value.wx_name}}
			</div>
			<div class="text-center mt16 ft14 cl-info">
				{{value.wx_principal_name}}
			</div>
			<div class="flex center mt24">
				<div class="version-box">
					<span class="ft14 cl-main ftw600">当前版本：</span>
					<span class="ft14 ftw600" style="color:#5333FF;">{{value.v ? 'v' + value.v : 'v1.0'}}</span>
				</div>
			</div>
			
			
			<div class="mt30 flex center">
				<miniapp-test></miniapp-test>				
				<div style="margin-left: 20px;">
					<miniapp-authurl title="重新授权"></miniapp-authurl>
				</div>
				<div style="margin-left: 20px;" v-if="value.is_new == 1">
					<miniapp-upload-act  title="升级新版"></miniapp-upload-act>
				</div>
			</div>
		</div>
</div>		
</template>

<script>
	import miniappTest from './test.vue';
	import  miniappAuthurl from './authurl.vue';
	import miniappUploadAct from './uploadAct.vue';
	export default {
		components:{
			miniappTest,
			miniappAuthurl,
			miniappUploadAct
		},
		props:{
			value:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		data() {
			return {
				showQrcode: false,
				showUploadForm:false,
				confirmLoading:false,
				labelCol: { span: 4 },
				wrapperCol: { span: 14 },
					  
				form:{
					cat:'',
					title:'',
					tag:'',
				},
			}
		},
		created() {

		},
		methods:{
			
			upgradeAct(){
				this.showUploadForm = true;
			},
			
			handleOk(){
				
			},
			handleCancel(){
				this.showUploadForm = false;
			}
		}
	}
</script>

<style>
	.miniapp-form-box {
		width: 1000px;
		min-height: 422px;
		background: #FAFDFF;
		border-radius: 4px;
		border: 1px solid #F0F3F5;
		padding: 60px;
	}

	.miniapp-logo {
		width: 80px;
		height: 80px;
		border-radius: 40px;
	}

	.version-box {
		width: 160px;
		height: 50px;
		border-top: 1px solid #E8EBED;
		border-bottom: 1px solid #E8EBED;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
