<template>
	<div>
		<a-spin :spinning="loading">
			<div class="mb20">
				<span class="ft20 cl-main ftw600">{{value.is_upgrade == 1 ? '升级覆盖线上' : '发布上线'}}</span>
			</div>

			<div class="miniapp-form-box mt30">
				<div class="text-center">
					<img class="miniapp-logo" :src="value.wx_face" />
				</div>
				<div class="text-center mt16 ft18 ftw600 cl-main">
					{{value.wx_name}}
				</div>
				<div class="text-center mt16 ft14 cl-info">
					{{value.wx_principal_name}}
				</div>
				<div class="flex center mt24">
					<div class="version-box">
						<span class="ft14 cl-main ftw600">当前版本：</span>
						<span class="ft14 ftw600" style="color:#5333FF;">{{value.v ? 'v' + value.v : 'v1.0'}}</span>
					</div>
				</div>

				<div class="mt30 flex center">

					<miniapp-test></miniapp-test>
					<a-button style="margin-left: 20px;" @click="onlineAct" type="primary">发布上线</a-button>

				</div>



			</div>
		</a-spin>

	</div>
</template>

<script>
	import miniappTest from './test.vue';
	export default {
		components: {
			miniappTest
		},
		props: {
			value: {
				type: Object,
				default: function() {
					return new Object;
				}
			}
		},
		data() {
			return {
				showQrcode: false,
				loading: false,
			}
		},
		created() {

		},
		methods: {
			onlineAct() {
				this.loading = true;
				this.$http.papi('platform/miniapp/online').then(res=>{
					this.loading = false;
					this.$message.success('发布成功');
					this.refreshPage('/miniapp');
				}).catch(res=>{
					this.loading = false;
				});
			}
		}
	}
</script>

<style>
	.miniapp-form-box {
		width: 1000px;
		min-height: 422px;
		background: #FAFDFF;
		border-radius: 4px;
		border: 1px solid #F0F3F5;
		padding: 60px;
	}

	.miniapp-logo {
		width: 80px;
		height: 80px;
		border-radius: 40px;
	}

	.version-box {
		width: 160px;
		height: 50px;
		border-top: 1px solid #E8EBED;
		border-bottom: 1px solid #E8EBED;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
